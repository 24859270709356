const schema = {
  "@context": "https://schema.org",
  "@type": "SoftwareApplication",
  "name": "Pina - Plan, Save & Invest",
  "operatingSystem": "ANDROID",
  "applicationCategory": "FinanceApplication",
  "installUrl":"https://pina.onelink.me/6Kj9/cffaaa19",
  "screenshot": "https://pina-assets.s3.ap-southeast-1.amazonaws.com/images/g/mobile-app-preview.png",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.6",
    "reviewCount": "892"
  },
  "offers": {
    "@type": "Offer",
    "price": "0",
    "priceCurrency": "RP"
  }
}

export default schema