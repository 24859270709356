import React, { createContext, useReducer } from 'react'
import reducers from './reducers'

const initialState = {
  flash_message: {},
  retirement_calc: {}
}

const Store = ({children}) => {
  const [state, dispatch] = useReducer(reducers, initialState)
  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
}

export const Context = createContext(initialState)
export default Store