const schema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Pina",
  "url": "https://pina.id/",
  "logo": "https://pina-assets.s3.ap-southeast-1.amazonaws.com/logos/logo-512w.png",
  "address" : {
    "@type": "PostalAddress",
    "addressCountry": "Indonesia",
    "addressRegion": "DKI Jakarta",
    "addressLocality": "Jakarta Selatan",
    "postalCode": "12190",
    "streetAddress": "Prosperity Tower Lantai 2E, District 8, SCBD Lot 28, Jl. Jenderal Sudirman Kav. 52-53"
  },
  "sameAs": [
    "https://www.instagram.com/pina.id/",
    "https://twitter.com/PINA_ID/",
    "https://www.youtube.com/channel/UCAoXK3v89kJ03dNLsiyA2KQ/",
    "https://id.linkedin.com/company/pinaindonesia/"
  ]
};

export default schema;
