import '../styles/globals.css'
import { useEffect, useState } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { IntercomProvider } from 'react-use-intercom'
import Store from '@/store/index'
import softwareAppSchema from '@/constants/schemas/software-app'
import orgSchema from '@/constants/schemas/organization'
// import { GTM_ID, pageview as gtmPageView } from '@/lib/gtm'

const isProduction = process.env.NODE_ENV === 'production' && /pina\.id/gi.test(process.env.NEXT_PUBLIC_BASE_URL)
const enableAnalytics= process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === 'true'
const enableIntercom = process.env.NEXT_PUBLIC_ENABLE_INTERCOM === 'true'
const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID

export default function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const getLayout = Component.getLayout || ((page) => page)
  const [isRenderGTM, setIsRenderGTM] = useState(false)


  // useEffect(() => {
  //   import('react-facebook-pixel')
  //     .then((x) => x.default)
  //     .then((ReactPixel) => {
  //       ReactPixel.init('275475188528583')
  //       ReactPixel.pageView()

  //       router.events.on('routeChangeComplete', () => {
  //         ReactPixel.pageView()
  //       })
  //     })
  //   router.events.on('routeChangeComplete', gtmPageView)
  //   return () => {
  //     router.events.off('routeChangeComplete', gtmPageView)
  //   }
  // }, [router.events])

  // useEffect(() => {
  //   if (!isRenderGTM) {
  //     setTimeout(() => {
  //       setIsRenderGTM(true)
  //     }, 3500)
  //   }
  // }, [isRenderGTM])

  return getLayout(
    <Store>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Pina Trade - Semua Kebutuhan Trading Saham dalam Satu Platform</title>
        <meta name=" description" content= "Semua Kebutuhan Trading Saham dalam Satu Platform" ></meta>
      </Head>

      {
        (isProduction || enableAnalytics) && isRenderGTM &&
        <Script id="gtm" strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','G-6W0BXC6Q1H');`}}></Script>
        
      }

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(orgSchema, null, 2) }}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(softwareAppSchema, null, 2) }}
      />

      
        <Component {...pageProps} />
      
    </Store>
  )
}
